import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  measurementProps,
  measurementStatus,
  pipelineProps,
  planProps,
} from "../../../../store/scheduler/types";
import "./measurementListItem.css";
import Collapsible from "react-collapsible";
import { Link } from "react-router-dom";
import {
  convertToReadableDate,
  getMeasurementStatus,
  getMeasurementStatusFromPipeline,
} from "../../../../store/scheduler/helpers";
import {
  removeMeasurement,
  setSelectedPlan,
  updateMeasurementStatus,
} from "../../../../store/scheduler/actions";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";
import { Input } from "@progress/kendo-react-inputs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { StatusProgress } from "./statusProgress";
import { setLastVisitedPlanAndMeasurement } from "../../../../helpers/genericHelpers";
import { appState } from "../../../../store";
import { roleType, userType } from "../../../../store/user/types";
import * as measurementsAPI from "./../../../../api/netRail/measurements";
import * as pipelineAPI from "./../../../../api/netRail/pipelines";
import { useTranslation } from "react-i18next";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { reduxSetNotification } from "../../../../store/user/actions";
import { Popup as PopupK } from "@progress/kendo-react-popup";

// Optimizations a la Joel
import ReprocessAcceptButton from "./ReprocessAcceptButton";
import ReprocessCancelButton from "./ReprocessCancelButton";
import Header from "./Header";

interface MeasurementListItemProps {
  measurement: measurementProps;
  measurementNumber: number;
  plan?: planProps;
  isSelected?: {
    borderStyle: string;
    borderColor: string;
    borderWidth: string;
  };
  selectedMeasurement?: boolean;
}

const speedTranslator = (railClass: string) => {
  if (railClass === "H0") {
    return "V\u226440";
  } else if (railClass === "H1") {
    return "40\u003CV\u226480";
  } else if (railClass === "H2") {
    return "80\u003CV\u2264120";
  } else if (railClass === "H3") {
    return "120\u003CV\u2264160";
  } else if (railClass === "H4") {
    return "160\u003CV\u2264200";
  } else if (railClass === "H5") {
    return "200\u003CV\u2264250";
  } else {
    return "";
  }
};

const valueToStateConverter = (value: string) => {
  if (value === "BIS") return "";
  return value.substring(0, 2);
};

const stateToValueConverter = (state: string) => {
  if (state == "" || state == "-") return "BIS";
  return state + " " + speedTranslator(state); //.substring(0, 2);
};

const numberRegex = new RegExp(/^[0-9]*$/);

const MeasurementListItem: React.FC<MeasurementListItemProps> = ({
  measurement,
  measurementNumber,
  plan,
  isSelected,
  selectedMeasurement,
}) => {
  const [arrowState, setArrow] = useState(false);
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [showMore, setShowMore] = useState(false);
  // const [pipelines, setPipeline] = useState<pipelineProps[]>();
  const [trackNumberValue, setTrackNumberValue] = useState<string | undefined>(
    measurement.trackNumber ? measurement.trackNumber : "-"
  );

  const prevServerPipelines = useRef<pipelineProps[]>();
  const prevServerMeasurement = useRef<measurementProps>();

  const [trackNumberValid, setTrackNumberValid] = useState<boolean>(true);
  const [speedClassValue, setSpeedClassValue] = useState<string>(
    measurement.classification ? measurement.classification : ""
  );
  const [startAtKmValue, setStartAtKmValue] = useState<any>(
    measurement.startAtKm
  );
  const [startAtKmValid, setStartAtKmValid] = useState<boolean>(true);
  const [startAtMeterValue, setStartAtMeterValue] = useState<any>(
    measurement.startAtMeter
  );
  const [startAtMeterValid, setStartAtMeterValid] = useState<boolean>(true);
  const [endAtKmValue, setEndAtKmValue] = useState<any>(
    measurement.endAtKm ? measurement.endAtKm : 0
  );
  const [endAtKmValid, setEndAtKmValid] = useState<boolean>(true);
  const [endAtMeterValue, setEndAtMeterValue] = useState<any>(
    measurement.endAtMeter ? measurement.endAtMeter : 0
  );
  const [endAtMeterValid, setEndAtMeterValid] = useState<boolean>(true);
  const [reprocessPressed, setReprocessPressed] = useState<boolean>(false);
  const [collapsibleOpen, setCollapsibleOpen] = useState<boolean>(false);
  const [invalidEndPoint, setInvalidEndPoint] = useState<boolean>(false);
  const [showReprocessPopup, setShowReprocessPopup] = useState(false);
  const [localySavedMeasurement, setLocalySavedMeasurement] =
    useState<measurementProps>(measurement);
  const [allowReprocess, setAllowReprocess] = useState<boolean>(true);

  const allCompanyUsers = useSelector(
    (state: appState) => state.user.allCompanyUsers
  );
  const allUsers = useSelector((state: appState) => state.user.allContacts);

  const currentUser = useSelector((state: appState) => state.user.currentUser);
  const isAdmin = useMemo(
    () => currentUser?.roles[0].name === "ADMIN",
    [currentUser]
  );

  const handleOpen = useCallback(() => {
    setRefreshStatus(true);
    flipArrow(arrowState, setArrow);
    setCollapsibleOpen(true);
  }, [arrowState, setArrow]);

  const handleClose = useCallback(() => {
    setRefreshStatus(false);
    flipArrow(arrowState, setArrow);
    setCollapsibleOpen(false);
  }, [arrowState, setArrow]);

  const numberValidator = (input?: string) =>
    input && numberRegex.test(input) ? "" : t("common.number");
  const numberValidatorForMeter = (input?: string) =>
    !(input && numberRegex.test(input))
      ? t("common.number")
      : +input > 1100
      ? t("common.lessThan1100m")
      : "";
  const notEmptyValidator = (value?: string) =>
    value && value.length > 0 ? "" : t("common.cannotBeEmptyValidator");

  useEffect(() => {
    if (isSelected) {
      setArrow(true);
    } else {
      setArrow(false);
    }
  }, [isSelected]);

  const refreshStatusFunction = useCallback(async () => {
    if (measurement)
      try {
        const serverPipelines = await pipelineAPI.getCurrentPipelines(
          measurement.id
        );
        const serverMeasurement = await measurementsAPI.getMeasurement(
          measurement.id
        );
        // Only dispatch and setPipeline if serverPipelines or serverMeasurement has changed
        if (
          JSON.stringify(serverPipelines) !==
            JSON.stringify(prevServerPipelines.current) ||
          JSON.stringify(serverMeasurement) !==
            JSON.stringify(prevServerMeasurement.current)
        ) {
          // all of these causes one rerender each:
          dispatch(
            updateMeasurementStatus(measurement.id, {
              status: serverMeasurement?.status,
              errorMessage: serverMeasurement?.errorMessage,
              pipelines: serverPipelines,
            })
          );

          // Update the previous values after dispatching and setting the state
          prevServerPipelines.current = serverPipelines;
          prevServerMeasurement.current = serverMeasurement;
        }
      } catch {
        console.log("Couldn't update measurement status!");
      }
  }, [measurement, dispatch, prevServerPipelines, prevServerMeasurement]);

  // Only rerenders if status has changed
  const memoizedRefreshStatusFunction = useMemo(
    () => refreshStatusFunction,
    [refreshStatusFunction]
  );

  useEffect(() => {
    let counter = 1000;
    if (measurement.status === measurementStatus.SendingEmailDone) {
      // Increase the wait time if the measurement is "done",
      // this so that we don't spam unnecessarily.
      counter = 5000;
    }

    const interval = setInterval(() => {
      const newestProcessNumber =
        measurement.pipelines && measurement.pipelines.length > 0
          ? measurement.pipelines?.reduce((prevPipe, currPipe) =>
              prevPipe.processNumber > currPipe.processNumber
                ? prevPipe
                : currPipe
            ).processNumber
          : 0;
      const currentPipelines = measurement.pipelines?.filter(
        (pipe) => pipe.processNumber === newestProcessNumber
      );
      if (
        (refreshStatus && !reprocessPressed) ||
        currentPipelines === undefined
      ) {
        memoizedRefreshStatusFunction();
      }
    }, counter);

    if (!refreshStatus && measurement.pipelines !== undefined) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [
    refreshStatus,
    selectedMeasurement,
    measurement,
    measurement.pipelines?.length,
  ]);

  const dismountResetFunction = async () => {
    if (measurement)
      try {
        const serverPipelines = await pipelineAPI.getCurrentPipelines(
          measurement.id
        );

        const serverMeasurement = await measurementsAPI.getMeasurement(
          measurement.id
        );

        // Reset states
        setTrackNumberValue(serverMeasurement.trackNumber);
        setTrackNumberValid(true);
        setSpeedClassValue(serverMeasurement.classification);
        setStartAtKmValue(serverMeasurement.startAtKm);
        setStartAtKmValid(true);
        setStartAtMeterValue(serverMeasurement.startAtMeter);
        setStartAtMeterValid(true);
        setEndAtKmValue(serverMeasurement.endAtKm);
        setEndAtKmValid(true);
        setEndAtMeterValue(serverMeasurement.endAtMeter);
        setEndAtMeterValid(true);
        setInvalidEndPoint(false);

        dispatch(
          updateMeasurementStatus(measurement.id, {
            status: serverMeasurement.status,
            errorMessage: serverMeasurement.errorMessage,
            pipelines: serverPipelines,
            trackNumber: serverMeasurement.trackNumber,
            classification: serverMeasurement.classification,
            startAtKm: serverMeasurement.startAtKm,
            startAtMeter: serverMeasurement.startAtMeter,
            endAtKm: serverMeasurement.endAtKm,
            endAtMeter: serverMeasurement.endAtMeter,
          })
        );
      } catch {
        console.log("Couldn't update measurement status! dismount");
      }
  };

  useEffect(() => {
    memoizedRefreshStatusFunction(); // Initial fetch of data
    return () => {
      dismountResetFunction();
    };
  }, []);

  const arrowImg = useMemo(() => {
    return (
      <span
        style={{ marginRight: "auto" }}
        className={
          "k-icon " +
          (arrowState ? "k-i-arrow-chevron-up" : "k-i-arrow-chevron-down")
        }
      />
    );
  }, [arrowState]);

  const handleEndpointFunction = (value: number, field: string) => {
    let startSmallerThanEnd: boolean;

    if (endAtKmValue === 0 && endAtMeterValue === 0) {
      // "Empty endpoint", we will be using encoder data
      setInvalidEndPoint(false);
      return;
    }

    switch (field) {
      case "startAtKm":
        startSmallerThanEnd =
          +value * 1000 + +startAtMeterValue <
          +endAtKmValue * 1000 + +endAtMeterValue;

        break;
      case "startAtMeter":
        startSmallerThanEnd =
          +startAtKmValue * 1000 + +value <
          +endAtKmValue * 1000 + +endAtMeterValue;
        break;
      case "endAtKm":
        startSmallerThanEnd =
          +startAtKmValue * 1000 + +startAtMeterValue <
          +value * 1000 + +endAtMeterValue;

        if (+value === 0 && +endAtMeterValue === 0)
          startSmallerThanEnd = !startSmallerThanEnd;

        break;
      case "endAtMeter":
        startSmallerThanEnd =
          +startAtKmValue * 1000 + +startAtMeterValue <
          +endAtKmValue * 1000 + +value;

        if (+value === 0 && +startAtMeterValue === 0)
          startSmallerThanEnd = !startSmallerThanEnd;

        break;
      default:
        startSmallerThanEnd =
          +startAtKmValue * 1000 + +startAtMeterValue <
          +endAtKmValue * 1000 + +endAtMeterValue;
    }

    setInvalidEndPoint(!startSmallerThanEnd);
  };

  const renderTrackNumber = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Input
          name={"trackNumber"}
          style={{ maxWidth: "30px" }}
          value={trackNumberValue}
          valid={trackNumberValid}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={(e) => {
            setTrackNumberValue(e.target.value);
            if (notEmptyValidator(e.target.value) !== "") {
              setTrackNumberValid(false);
            } else {
              setTrackNumberValid(true);
            }
          }}
          onBlur={(e) => {
            if (trackNumberValue)
              dispatch(
                updateMeasurementStatus(measurement.id, {
                  trackNumber: trackNumberValue,
                })
              );
          }}
        />
        <span style={{ fontSize: "11px", color: "red" }}>
          {notEmptyValidator(trackNumberValue)}
        </span>
      </div>
    );
  };

  const renderSpeedClassDropDownList = () => {
    return (
      <DropDownList
        style={{ width: "150px" }}
        data={[
          "BIS",
          "H0 V\u226440",
          "H1 40\u003CV\u226480",
          "H2 80\u003CV\u2264120",
          "H3 120\u003CV\u2264160",
          "H4 160\u003CV\u2264200",
          "H5 200\u003CV\u2264250",
        ]}
        value={stateToValueConverter(speedClassValue)}
        onChange={(e) => {
          const value = valueToStateConverter(e.target.value);
          setSpeedClassValue(value);
        }}
        onBlur={() => {
          dispatch(
            updateMeasurementStatus(measurement.id, {
              classification: valueToStateConverter(speedClassValue),
            })
          );
        }}
        onClose={(e) => {
          // Changed from onblur
          valueToStateConverter
            ? valueToStateConverter(e.target.value)
            : (e.target.value as any);
        }}
      />
    );
  };

  const renderStartOnFields = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: "10px",
        }}
      >
        {"km: "}
        <Input
          name={"startAtKm"}
          value={startAtKmValue}
          valid={startAtKmValid}
          style={{ maxWidth: "30px" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={(e) => {
            if (numberValidator(e.target.value) !== "") {
              setStartAtKmValue(e.target.value);
              setStartAtKmValid(false);
            } else {
              setStartAtKmValue(+e.target.value);
              setStartAtKmValid(true);
              handleEndpointFunction(+e.target.value, "startAtKm");
            }
          }}
          onBlur={(e) => {
            dispatch(
              updateMeasurementStatus(measurement.id, {
                startAtKm: startAtKmValue,
              })
            );
          }}
        />

        {" m: "}
        <Input
          name={"startAtMeter"}
          value={startAtMeterValue}
          valid={startAtMeterValid}
          style={{ maxWidth: "30px" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={(e) => {
            if (numberValidatorForMeter(e.target.value) !== "") {
              setStartAtMeterValue(e.target.value);
              setStartAtMeterValid(false);
            } else {
              setStartAtMeterValue(+e.target.value);
              setStartAtMeterValid(true);
              handleEndpointFunction(+e.target.value, "startAtMeter");
            }
          }}
          onBlur={(e) => {
            dispatch(
              updateMeasurementStatus(measurement.id, {
                startAtMeter: startAtMeterValue,
              })
            );
          }}
        />
      </div>
    );
  };

  const renderEndOnFields = () => {
    return (
      <div
        style={{
          display: "flex",
          // flexDirection: parentWidth < cPhoneWidthLim ? "row" : "column",
          // alignItems: parentWidth < cPhoneWidthLim ? "center" : "flex-start",
          alignItems: "center",
          justifyContent: "center",
          columnGap: "10px",
        }}
      >
        {"km: "}
        <Input
          name={"endAtKm"}
          value={endAtKmValue}
          valid={endAtKmValid}
          style={{ maxWidth: "30px" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={(e) => {
            if (numberValidator(e.target.value) !== "") {
              setEndAtKmValue(e.target.value);
              setEndAtKmValid(false);
            } else {
              setEndAtKmValue(+e.target.value);
              setEndAtKmValid(true);
              handleEndpointFunction(+e.target.value, "endAtKm");
            }
          }}
          onBlur={(e) => {
            dispatch(
              updateMeasurementStatus(measurement.id, {
                endAtKm: endAtKmValue,
              })
            );
          }}
        />
        {" m: "}
        <Input
          name={"endAtMeter"}
          validate={true}
          value={endAtMeterValue}
          valid={endAtMeterValid}
          style={{ maxWidth: "30px" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onChange={(e) => {
            if (numberValidatorForMeter(e.target.value) !== "") {
              setEndAtMeterValue(e.target.value);
              setEndAtMeterValid(false);
            } else {
              setEndAtMeterValue(+e.target.value);
              setEndAtMeterValid(true);
              handleEndpointFunction(+e.target.value, "endAtMeter");
            }
          }}
          onBlur={(e) => {
            dispatch(
              updateMeasurementStatus(measurement.id, {
                endAtMeter: endAtMeterValue,
              })
            );
          }}
        />
      </div>
    );
  };

  const MeasurementGroupOfObjects = () => {
    return (
      <div className="DropdownFieldWrapper">
        <Header
          measurementNumber={measurementNumber}
          createdAt={convertToReadableDate(measurement.createdAt)}
          arrowImg={arrowImg}
          reprocessTag={measurement.reprocessed ? true : false}
        />

        <div className="DropdownField" style={isSelected ? isSelected : {}}>
          <div className="DropdownFieldColumn">
            <div className="MeasurementContentRow">
              <div
                className="body-1 CardHeaderItem"
                style={{ color: "var(--primary-color)" }}
              >
                <p className="light-italic-text">
                  {t("measurementListItem.measurementStatus")}
                </p>
                <div className="measurementStatusText">
                  {getMeasurementStatusFromPipeline(t, measurement.pipelines)}

                  {reprocessPressed ? ( // Sneaky way to align the rows when editing the values
                    <Input style={{ opacity: 0, width: 0 }} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <p className="light-italic-text">
                {t("measurementListItem.startOn")}
              </p>
              <div className="measurementStatusText">
                {reprocessPressed
                  ? renderStartOnFields()
                  : measurement.startAtKm +
                    " + " +
                    measurement.startAtMeter +
                    " m"}
              </div>
              <p style={{}}>{measurement.startAt ? measurement.startAt : ""}</p>
              <span
                style={{
                  fontSize: "11px",
                  color: "red",
                }}
              >
                {startAtKmValid ? null : numberValidator(startAtKmValue)}
                {startAtMeterValid
                  ? null
                  : numberValidatorForMeter(startAtMeterValue)}
              </span>
            </div>
          </div>
          <div className="DropdownFieldColumn">
            <div className="MeasurementContentRow">
              <div
                className="body-1 CardHeaderItem"
                style={{ color: "var(--primary-color)" }}
              >
                <p className="light-italic-text">
                  {t("measurementListItem.trackNumber")}
                </p>
                <div className="measurementStatusText">
                  {reprocessPressed
                    ? renderTrackNumber()
                    : measurement.trackNumber
                    ? measurement.trackNumber
                    : "-"}
                </div>
              </div>
              <p className="light-italic-text">
                {t("measurementListItem.endOn")}
              </p>
              <div className="measurementStatusText">
                {reprocessPressed
                  ? renderEndOnFields()
                  : !(measurement.endAtKm === 0 && measurement.endAtMeter === 0)
                  ? measurement.endAtKm + " + " + measurement.endAtMeter + " m"
                  : " -"}
              </div>
              {measurement.endAt ? measurement.endAt : ""}
              <span
                style={{
                  fontSize: "11px",
                  color: "red",
                }}
              >
                {endAtKmValid ? null : numberValidator(endAtKmValue)}
                {endAtMeterValid
                  ? null
                  : numberValidatorForMeter(endAtMeterValue)}
              </span>
            </div>
          </div>

          <div className="DropdownFieldSpeedClass">
            <div className="SpeedclassFlexContainer">
              <div className="body-1 CardHeaderItem">
                <p className="light-italic-text">
                  {t("measurementListItem.speedClass")}
                </p>
                <div className="measurementStatusText">
                  {reprocessPressed
                    ? renderSpeedClassDropDownList()
                    : measurement.classification !== undefined
                    ? stateToValueConverter(measurement.classification)
                    : "-"}
                </div>
              </div>
              <div className="body-1 CardHeaderItem">
                {!reprocessPressed ? (
                  !measurement.reprocessed ? (
                  <>
                    {reprocessButton}
                    {deleteButton}
                  </>
                  ) : (
                    <>
                    {deleteButton}
                    </> 
                  )
                ) : (
                  <>
                    <ReprocessAcceptButton
                      startAtKmValid={startAtKmValid}
                      startAtMeterValid={startAtMeterValid}
                      endAtKmValid={endAtKmValid}
                      endAtMeterValid={endAtMeterValid}
                      trackNumberValid={trackNumberValid}
                      invalidEndPoint={invalidEndPoint}
                      setShowReprocessPopup={setShowReprocessPopup}
                    />
                    <ReprocessCancelButton
                      dismountResetFunction={dismountResetFunction}
                      setReprocessPressed={setReprocessPressed}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {!(reprocessPressed && invalidEndPoint) ? null : (
          <div style={{ color: "red", padding: "5px" }}>
            {t("planForm.errors.startGreaterThanEnd")}
          </div>
        )}
      </div>
    );
  };

  const renderDeleteMeasurementForm = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement>
        <div
          className="deletePlanPwField"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Field
            name={"delete"}
            placeholder={t("common.delete")}
            component={Input}
            label={t("measurementListItem.confirmDelete")}
          />
        </div>
        <div className="DeletePlanButtonContainer">
          <button
            className="PopupButton BUTTON"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShow(!show);
            }}
          >
            {t("common.cancel")}
          </button>
          <button
            type={"submit"}
            className="PopupButton BUTTON"
            disabled={
              formRenderProps.valueGetter("delete") !== t("common.delete")
            }
            style={{
              opacity:
                formRenderProps.valueGetter("delete") !== t("common.delete")
                  ? 0.5
                  : 1,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {t("common.delete")}
          </button>
        </div>
      </FormElement>
    );
  };

  const deleteButton = (
    <>
      <span
        className={
          currentUser?.roles[0].name !== roleType.admin
            ? "non-admin-delete-button k-icon k-i-delete removeChart"
            : "admin-delete-button k-icon k-i-delete removeChart"
        }
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShow(!show);
        }}
      />
      <Popup
        className="popupStyle"
        show={show}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(120, 120, 120, 0.5)",
          zIndex: 100000000000000,
        }}
      >
        <div className="PopupContentShare">
          <p style={{ fontWeight: "bold" }}>
            {t("measurementListItem.deleteMeasurement")}
          </p>
          <Form
            initialValues={{ deletePlan: "" }}
            onSubmit={(dataItem) => {
              dispatch(removeMeasurement(t, measurement.id));
              setShow(!show);
            }}
            render={(formRenderProps) =>
              renderDeleteMeasurementForm(formRenderProps)
            }
          />
        </div>
      </Popup>
    </>
  );

  const reprocessButton = (
    <>
      <span
        className="k-icon k-i-wrench non-admin-delete-button removeChart"
        title={t("measurementListItem.reprocess").toString()}
        style={
          getMeasurementStatus(t, measurement.status) ===
            t("common.measurementStatus.inProgress") &&
          getMeasurementStatusFromPipeline(t, measurement.pipelines) !==
            t("common.measurementStatus.faulty")
            ? {
                pointerEvents: "none",
                opacity: "0.5",
              }
            : {
                pointerEvents: "all",
              }
        }
        onClick={(e) => {
          if (collapsibleOpen) {
            e.preventDefault();
            e.stopPropagation();
          }
          setLocalySavedMeasurement(measurement);
          setReprocessPressed(true);
        }}
      />
    </>
  );

  const dropdownContent = () => {
    return (
      <div className="PlanContentFieldContainer">
        <StatusProgress
          measurement={measurement}
          pipelines={measurement.pipelines}
        />
        <div className="PlanContentRowContainer">
          <div className="PlanContentFieldHeading">
            {t("measurementListItem.creator")}
          </div>
          <div className="PlanContentNoLink">
            {getMeasurmentCreator(measurement.creatorID)}
          </div>
        </div>
        <div className="PlanContentRowContainer">
          <div className="PlanContentFieldHeading">
            {t("measurementListItem.shared")}
          </div>
          <div className="PlanContentNoLink">
              <div className={measurement.receivers.length > 1 ? "ScrollableBox": ""} >
                {measurement.receivers
                  ? measurement.receivers.map((str) => {
                      if (str !== "") return <span key={str}>{str.concat("\n")}</span>;
                    })
                  : measurement.receivers}
              </div>
            </div>
        </div>
        <div className="PlanContentRowContainer">
          <div className="PlanContentFieldHeading">
            {t("measurementListItem.notes")}
          </div>
        </div>
        <div
          className="PlanContentRowNotesWrapper"
          style={
            !measurement.notes
              ? {}
              : measurement.notes.split("\n").length < 3
              ? {}
              : showMore
              ? {}
              : {
                  overflow: "hidden",
                  maxHeight: "110px",
                  boxShadow: "inset 0px -10px 10px -10px rgba(0, 0, 0, 0.26)",
                }
          }
        >
          {measurement.notes
            ? measurement.notes.split("\n").map((str) => {
                if (str !== "") return <p key={str}>{str}</p>;
              })
            : measurement.notes}
        </div>
        {!measurement.notes ? null : measurement.notes.split("\n").length <
          3 ? null : showMore ? (
          <button
            className="ShowMoreButton"
            onClick={() => {
              setShowMore(false);
            }}
          >
            {t("measurementListItem.shrink")}
            <span
              style={{ marginRight: "auto", marginLeft: "auto" }}
              className={"k-icon  k-i-arrow-chevron-up"}
            />
          </button>
        ) : (
          <button
            className="ShowMoreButton"
            onClick={() => {
              setShowMore(true);
            }}
          >
            {t("measurementListItem.extend")}
            <span
              style={{ marginRight: "auto", marginLeft: "auto" }}
              className={"k-icon  k-i-arrow-chevron-down"}
            />
          </button>
        )}
        <div className="PlanContentRowContainer">
          <div className="PlanContentNoLink">
            <div style={{ color: "black", fontWeight: "bold" }}>
              {t("measurementListItem.plan")}
              {": "}
            </div>
            {document.location.pathname === "/scheduler/plan" ? (
              <div className="PlanContentNoLink">{plan?.name}</div>
            ) : (
              <Link
                className="PlanContentLink"
                to="/scheduler/plan"
                onClick={() => {
                  setLastVisitedPlanAndMeasurement(plan?.id, undefined);
                  if (plan) dispatch(setSelectedPlan(plan.id));
                }}
              >
                {"      "}
                {plan?.name} <span className="k-icon k-i-redo GoToIcon" />
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  };

  function getMeasurmentCreator(creatorID: string) {
    if (currentUser?.roles[0].name === roleType.admin) {
      if (allUsers) {
        const creator = allUsers.find(
          (user: userType) => user.id === creatorID
        );
        return creator ? creator.email : "";
      } else {
        return "";
      }
    } else {
      const creator = allCompanyUsers.find(
        (user: userType) => user.id === creatorID
      );
      return creator ? creator.email : "";
    }
  }

  return (
    <div>
      <div className="MeasurementListItemContainer">
        {isAdmin ? <span>MeasID: {measurement.id}</span> : null}
        <Collapsible
          className="Collapsible"
          trigger={MeasurementGroupOfObjects()}
          onOpening={handleOpen}
          onClosing={handleClose}
          onKeyDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          triggerDisabled={reprocessPressed}
          onFocus={(e) => {
            e.preventDefault();
          }}
          open={
            window.location.href.includes("plan") ? false : selectedMeasurement
          }
        >
          {dropdownContent()}
        </Collapsible>
      </div>
      <PopupK
        show={showReprocessPopup}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(120, 120, 120, 0.5)",
        }}
      >
        <div className="PopupContentShare">
          <p style={{ fontWeight: "bold" }}>{t("planForm.observe")}</p>
          <p>{t("measurementListItem.reprocessRecap")}</p>
          <p>
            {t("common.trackNumber") + ": "}
            {trackNumberValue?.toString()}
            <br />
            {t("common.speedClass") + ": "}
            {stateToValueConverter(speedClassValue)}
            <br />
            {t("measurementListItem.startOn")}{" "}
            {startAtKmValue.toString() +
              "km + " +
              startAtMeterValue.toString() +
              "m"}
            <br />
            {t("measurementListItem.endOn")}{" "}
            {endAtKmValue.toString() +
              "km + " +
              endAtMeterValue.toString() +
              "m"}
          </p>
          <p>{t("measurementListItem.reprocessWarning")}</p>

          <div className="DeletePlanButtonContainer">
            <button
              className="PopupButton BUTTON"
              onClick={async () => {
                dismountResetFunction();
                setShowReprocessPopup(false);
              }}
            >
              {t("common.cancel")}
            </button>
            <button
              className="PopupButton BUTTON"
              style={!allowReprocess ? { opacity: "0.4" } : {}}
              disabled={!allowReprocess}
              onClick={async () => {
                setAllowReprocess(false);
                try {
                  const serverMeasurement = await measurementsAPI.getMeasurement(
                    measurement.id
                  );
                  serverMeasurement.reprocessCnt += 1;
                  await measurementsAPI.patchMeasurement(serverMeasurement);
                  try {
                  
                    await measurementsAPI.reprocessMeasurement(measurement);
                    await memoizedRefreshStatusFunction();
                    dispatch(
                      reduxSetNotification({
                        message: t("planForm.reprocessInfo"),
                        style: "info",
                        open: true,
                      })
                    );
                    setReprocessPressed(false);
                    setShowReprocessPopup(false);
                    setAllowReprocess(true);
                  } catch (error) {
                    dispatch(
                      updateMeasurementStatus(
                        localySavedMeasurement.id,
                        localySavedMeasurement
                      )
                    );
                    // setShow(false);

                    dispatch(
                      reduxSetNotification({
                        message: t("planForm.reprocessError"),
                        style: "error",
                        open: true,
                      })
                    );
                    setReprocessPressed(false);
                    setShowReprocessPopup(false);
                    setAllowReprocess(true);
                  }
                } catch (error) {
                  setReprocessPressed(false);
                  dismountResetFunction();
                  setShowReprocessPopup(false);
                  setAllowReprocess(true);
                }
              }}
            >
              {t("common.continue")}
            </button>
          </div>
        </div>
      </PopupK>
    </div>
  );

  function flipArrow(arrowState: boolean, setArrow: Function) {
    setArrow(!arrowState);
  }
};

export default React.memo(MeasurementListItem);
